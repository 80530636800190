/**
 * Page manager screen watchers helpers
 * @module services/pm-screen/watchers
 */
import { Map } from 'immutable'
import { getUserLanguagePrimary } from 'services/user'
import { selectVideos } from 'services/node'
import {
  GET_MULTIPLE_PM_SECTIONS,
  GET_PM_SECTION,
} from './actions'
import { getPmSectionMultiple, getPmSectionSingle } from './watchers-helpers'

export function onGetMultiplePmSections ({ after }) {
  return after(GET_MULTIPLE_PM_SECTIONS, async ({ dispatch, state, action }) => {
    const {
      app = Map(),
      auth = Map(),
      page = Map(),
      resolver = Map(),
      pmList = Map(),
      pmPlacement = Map(),
      pmScreen = Map(),
      pmSection = Map(),
      user = Map(),
    } = state

    const videos = selectVideos(state)

    const {
      payload: {
        sectionIds, screenType, limit, language = getUserLanguagePrimary(user),
      } = {},
    } = action
    await getPmSectionMultiple({
      dispatch,
      language,
      screenType,
      app,
      auth,
      page,
      resolver,
      pmList,
      pmPlacement,
      pmScreen,
      pmSection,
      videos,
      sectionIds,
      limit,
    })
  })
}

export function onGetPmSection ({ after }) {
  return after(GET_PM_SECTION, async ({ dispatch, state, action }) => {
    const {
      app = Map(),
      auth = Map(),
      page = Map(),
      pmList = Map(),
      pmPlacement = Map(),
      pmScreen = Map(),
      pmSection = Map(),
      user = Map(),
      resolver = Map(),
    } = state
    const videos = selectVideos(state)
    const {
      payload: {
        sectionId, screenType, language = getUserLanguagePrimary(user),
      } = {},
    } = action
    await getPmSectionSingle({
      dispatch,
      language,
      screenType,
      app,
      auth,
      page,
      resolver,
      pmList,
      pmPlacement,
      pmSection,
      pmScreen,
      videos,
      sectionId,
    })
  })
}
