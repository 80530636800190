import { NAVIGATION_HISTORY_PUSH } from 'services/navigation/actions'
import { URL_AMBASSADORS, URL_HOME } from 'services/url/constants'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_AUTH_LOGIN_SUCCESS } from 'services/auth/actions'
import { SET_RESOLVER_DATA, SET_RESOLVER_LOCATION } from 'services/resolver/actions'
import { ROLE_ID_AMBASSADOR } from 'services/auth-access'
import { TYPE_LOGIN } from 'services/dialog'
import { getAuthIsLoggedIn } from 'services/auth'
import { Map } from 'immutable'
import _get from 'lodash/get'
import {
  setOverlayDialogVisible,
  setOverlayCloseOnClick,
  setDialogOptions,
} from 'services/dialog/actions'
import { getDateTime } from 'services/date-time'
import {
  AMBASSADORS_GET_CAMPAIGNS,
  AMBASSADORS_GET_PAYOUT,
  AMBASSADORS_GET_SIGNUPS,
  AMBASSADORS_SET_CAMPAIGNS,
  AMBASSADORS_SET_CAMPAIGNS_PROCESSING,
  AMBASSADORS_SET_DETAILS,
  AMBASSADORS_SET_DETAILS_PROCESSING,
  AMBASSADORS_SET_PAYOUT,
  AMBASSADORS_SET_PAYOUT_PROCESSING,
  AMBASSADORS_SET_UPCOMING_CAMPAIGNS,
  AMBASSADORS_SET_UPCOMING_CAMPAIGNS_PROCESSING,
  AMBASSADORS_SET_SIGNUPS,
  AMBASSADORS_SET_SIGNUPS_PROCESSING,
  AMBASSADORS_SET_CAMPAIGN_DETAILS,
  AMBASSADORS_SET_CAMPAIGN_DETAILS_PROCESSING,
} from './actions'
import {
  getAmbassadorPayout,
  getAmbassador,
  getAmbassadorCampaigns,
  getAmbassadorSignUp,
  orderData,
  SIGNUPS_MAX_PP,
  PARTNER_SIGNUPS_TAB_VALUE,
  PARTNER_CAMPAIGNS_TAB_VALUE,
  PARTNER_PAYOUTS_TAB_VALUE,
  getAmbassadorCampaignDetails,
  getAmbassadorAllCampaigns,
} from '.'

function isAmbassadorsPage ({ state }) {
  const { resolver } = state
  return resolver.getIn(['data', 'path']) === URL_AMBASSADORS
}

function getUtmCampaign ({ state }) {
  const { resolver } = state
  return Map.isMap(resolver) && resolver.size > 0
    ? resolver.getIn(['query', 'utmCampaign'], false)
    : false
}

async function dispatchAmbassadorPayout (state, dispatch, year) {
  const { auth } = state

  dispatch({
    type: AMBASSADORS_SET_PAYOUT_PROCESSING,
    payload: { processing: true },
  })
  const data = await getAmbassadorPayout({ auth, year })
  dispatch({
    type: AMBASSADORS_SET_PAYOUT,
    payload: { data },
  })
}

async function dispatchAmbassadorDetails (state, dispatch) {
  const { auth } = state

  dispatch({
    type: AMBASSADORS_SET_DETAILS_PROCESSING,
    payload: { processing: true },
  })

  try {
    const data = await getAmbassador({ auth })

    const error = _get(data, 'errors')

    dispatch({
      type: AMBASSADORS_SET_DETAILS,
      payload: {
        data: error ? {} : data,
        error,
      },
    })
  } catch (error) {
    dispatch({
      type: AMBASSADORS_SET_DETAILS,
      payload: {
        data: {},
        error,
      },
    })
  }
}

async function dispatchAmbassadorSignUps (state, dispatch, payload = {}) {
  const { auth, resolver } = state
  const { p, year, month } = payload

  dispatch({
    type: AMBASSADORS_SET_SIGNUPS_PROCESSING,
    payload: { processing: true },
  })

  try {
    const pp = resolver.getIn(['query', 'pp'], SIGNUPS_MAX_PP)
    const page = p ?? resolver.getIn(['query', 'p'], 1)

    const data = await getAmbassadorSignUp({
      auth,
      year,
      month,
      p: page,
      pp,
    })

    const error = _get(data, 'errors')

    if (error) {
      dispatch({
        type: AMBASSADORS_SET_SIGNUPS,
        payload: {
          data: {},
          error,
        },
      })
    } else {
      dispatch({
        type: AMBASSADORS_SET_SIGNUPS,
        payload: { data },
      })
    }
  } catch (error) {
    dispatch({
      type: AMBASSADORS_SET_SIGNUPS,
      payload: {
        data: {},
        error,
      },
    })
  }
}

async function dispatchAmbassadorCampaigns (state, dispatch, year) {
  const { auth, resolver } = state

  dispatch({
    type: AMBASSADORS_SET_CAMPAIGNS_PROCESSING,
    payload: { processing: true },
  })

  try {
    let data = await getAmbassadorCampaigns({ auth, year })
    const sortDirection = resolver.getIn(['query', 'sortDirection'])
    const sortField = resolver.getIn(['query', 'sortField'])

    const error = _get(data, 'errors')

    if (error) {
      dispatch({
        type: AMBASSADORS_SET_CAMPAIGNS,
        payload: {
          data: [],
          error,
        },
      })
    } else {
      // Local sorting
      if (sortDirection && sortField) {
        data = orderData(data, sortDirection, sortField)
      }

      dispatch({
        type: AMBASSADORS_SET_CAMPAIGNS,
        payload: { data },
      })
    }
  } catch (error) {
    dispatch({
      type: AMBASSADORS_SET_CAMPAIGNS,
      payload: {
        data: [],
        error,
      },
    })
  }
}

async function dispatchAmbassadorUpcomingCampaigns (state, dispatch) {
  const { auth } = state

  dispatch({
    type: AMBASSADORS_SET_UPCOMING_CAMPAIGNS_PROCESSING,
    payload: { processing: true },
  })
  try {
    const data = await getAmbassadorAllCampaigns({ auth })

    const error = _get(data, 'errors')

    if (error) {
      dispatch({
        type: AMBASSADORS_SET_UPCOMING_CAMPAIGNS,
        payload: {
          data: [],
          error,
        },
      })
    } else {
      const date = new Date()
      const upcomingCampaigns = data
        .filter(({ campaignStartDate }) => getDateTime(campaignStartDate) > getDateTime(date))

      dispatch({
        type: AMBASSADORS_SET_UPCOMING_CAMPAIGNS,
        payload: { data: upcomingCampaigns },
      })
    }
  } catch (error) {
    dispatch({
      type: AMBASSADORS_SET_UPCOMING_CAMPAIGNS,
      payload: {
        data: [],
        error,
      },
    })
  }
}

async function dispatchAmbassadorCampaignDetails (state, dispatch, utmCampaign) {
  const { auth } = state

  dispatch({
    type: AMBASSADORS_SET_CAMPAIGN_DETAILS_PROCESSING,
    payload: { processing: true },
  })

  try {
    const data = await getAmbassadorCampaignDetails({ auth, utmCampaign })

    const error = _get(data, 'errors')

    dispatch({
      type: AMBASSADORS_SET_CAMPAIGN_DETAILS,
      payload: {
        data: error ? {} : data,
        error,
      },
    })
  } catch (error) {
    dispatch({
      type: AMBASSADORS_SET_CAMPAIGN_DETAILS,
      payload: {
        data: {},
        error,
      },
    })
  }
}

/**
 * Watcher for Ambassadors page "/ambassadors"
 * when the page is mounted.
 */
// eslint-disable-next-line import/prefer-default-export
export function watchAmbassadorsPageMount ({ after }) {
  return after(
    [
      SET_APP_BOOTSTRAP_PHASE,
      SET_AUTH_LOGIN_SUCCESS,
      SET_RESOLVER_DATA,
    ],
    async ({ dispatch, state }) => {
      const { auth } = state

      if (!getAuthIsLoggedIn(auth)) {
        dispatch(setOverlayDialogVisible(TYPE_LOGIN))
        dispatch(setDialogOptions(null, true))
        dispatch(setOverlayCloseOnClick(false))
      } else if (getAuthIsLoggedIn(auth) && auth.get('roleIds').includes(ROLE_ID_AMBASSADOR)) {
        dispatchAmbassadorPayout(state, dispatch)
        dispatchAmbassadorDetails(state, dispatch)
        dispatchAmbassadorCampaigns(state, dispatch)
        dispatchAmbassadorUpcomingCampaigns(state, dispatch)
        dispatchAmbassadorSignUps(state, dispatch, {})
      } else {
        dispatch({
          type: NAVIGATION_HISTORY_PUSH,
          payload: { url: URL_HOME },
        })
      }
    },
  )
    .when(isAmbassadorsPage)
}

/**
 * Watcher history push
 */
export function watchAmbassadorHistory ({ after }) {
  return after([
    NAVIGATION_HISTORY_PUSH,
  ], async ({ dispatch, state, action }) => {
    const { payload: { query } } = action
    const { tab } = query

    if (tab === PARTNER_SIGNUPS_TAB_VALUE) {
      dispatchAmbassadorSignUps(state, dispatch, query)
    }

    if (tab === PARTNER_CAMPAIGNS_TAB_VALUE) {
      dispatchAmbassadorCampaigns(state, dispatch)
    }

    if (tab === PARTNER_PAYOUTS_TAB_VALUE) {
      dispatchAmbassadorPayout(state, dispatch)
    }
  })
    .when(isAmbassadorsPage)
}

/**
 * Watcher for getting payouts data
 */
export function getAmbassadorPayouts ({ after }) {
  return after([
    AMBASSADORS_GET_PAYOUT,
  ], async ({ dispatch, state, action }) => {
    const { payload: year } = action

    dispatchAmbassadorPayout(state, dispatch, year)
  })
}

/**
 * Watcher for getting payouts data
 */
export function getCampaigns ({ after }) {
  return after([
    AMBASSADORS_GET_CAMPAIGNS,
  ], async ({ dispatch, state, action }) => {
    const { payload: year } = action

    dispatchAmbassadorCampaigns(state, dispatch, year)
  })
}

/**
 * Watcher for getting signups data
 */
export function getSignUps ({ after }) {
  return after([
    AMBASSADORS_GET_SIGNUPS,
  ], async ({ dispatch, state, action }) => {
    const { payload } = action

    dispatchAmbassadorSignUps(state, dispatch, payload)
  })
}

/**
 * Watcher campaign details
 */
export function watchAmbassadorCampaignDetails ({ after }) {
  return after([
    SET_RESOLVER_LOCATION,
    SET_APP_BOOTSTRAP_PHASE,
    SET_AUTH_LOGIN_SUCCESS,
    SET_RESOLVER_DATA,
  ], async ({ dispatch, state }) => {
    const utmCampaign = getUtmCampaign({ state })
    dispatchAmbassadorCampaignDetails(state, dispatch, utmCampaign)
  })
    .when((state) => (
      isAmbassadorsPage(state)
      && !!getUtmCampaign(state)
    ))
}
