/* eslint-disable import/prefer-default-export */
import { getPrimary as getPrimaryLanguage } from 'services/languages'

/**
 * If changes made and translation needed
 * values need to be excluded from translation in Smartling
 * @param {Object} options The options
 * @param {import('immutable').List|String[]|String} options.language The requested language
 */
export async function getFilterSortBy (options = {}) {
  const { language } = options
  const userLanguge = getPrimaryLanguage(language)
  let data = {}
  switch (userLanguge) {
    case 'es':
    case 'es-LA': {
      const result = await import('./lang_es-LA.json')
      data = result.default
      break
    }
    case 'de':
    case 'de-DE': {
      const result = await import('./lang_de-DE.json')
      data = result.default
      break
    }
    case 'fr':
    case 'fr-FR': {
      const result = await import('./lang_fr-FR.json')
      data = result.default
      break
    }
    case 'en':
    case 'en-US':
    default: {
      const result = await import('./lang_en.json')
      data = result.default
      break
    }
  }
  // Mimic and HTTP response shape putting the data on body
  return { body: data }
}
