/* eslint-disable import/prefer-default-export */
import { SET_EVENT_VIDEO_AUDIO_CHANGED } from 'services/event-tracking/actions'
import { addNewRelicAction } from 'services/app/_newrelic'
import isNil from 'lodash/isNil'

export function watchVideoAudioChangeForNewRelicEvent ({ after }) {
  return after(SET_EVENT_VIDEO_AUDIO_CHANGED, async ({ action, state }) => {
    const { user } = state
    const anonymousUuid = user.getIn(['data', 'anonymousUuid'])
    const userUuid = user.getIn(['data', 'uuid'])
    const { payload: { lang } } = action
    const newRelicData = {
      anonymousUuid,
      userUuid,
      selectedAudioTrack: lang,
      audioTrackChangeSuccess: !isNil(lang),
    }
    addNewRelicAction('VideoAudioChange', newRelicData)
  })
}
