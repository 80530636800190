import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect as connectStaticText } from 'components/StaticText/connect'
import Button, { TYPES as BUTTON_TYPES } from 'components/Button'
import Input from 'components/FormInput.v2/_Input'
import { TYPES } from 'components/FormInput.v2/utils'

function PasswordInput (props) {
  const { staticText, showHideTabIndex } = props
  const [show, setShow] = useState(false)
  const type = show ? TYPES.TEXT : TYPES.PASSWORD
  const hideShowButtonText = show ? staticText.getIn(['data', 'hide']) : staticText.getIn(['data', 'show'])

  return (
    <div className="forminput--password__wrapper">
      <Button
        type={BUTTON_TYPES.LINK}
        onClick={() => setShow(!show)}
        tabIndex={showHideTabIndex}
        className="forminput--password__show-button"
      >
        {hideShowButtonText}
      </Button>
      <Input type={type} {...props} />
    </div>
  )
}

PasswordInput.propTypes = {
  staticText: ImmutablePropTypes.map.isRequired,
  showHideTabIndex: PropTypes.string,
}

export default connectStaticText({ storeKey: 'passwordInputV2' })(PasswordInput)
