import { List } from 'immutable'
import get from 'lodash/get'
import { SET_EVENT_PAGE_VIEWED } from 'services/event-tracking/actions'
import { getAuthIsLoggedIn } from 'services/auth'
import {
  AUTH_LOGIN,
  SET_AUTH_DATA,
  AUTH_CHANGE_PROFILE,
  SET_AUTH_LOGIN_FAILED,
  SET_AUTH_LOGIN_SUCCESS,
} from 'services/auth/actions'
import { SET_USER_DATA, SET_USER_DATA_LANGUAGE, setAnonymousUuid } from 'services/user/actions'
import { setInterstitial, removeInterstitial } from 'services/interstitial/actions'
import { USER_PROFILES_SET, showPrompt } from 'services/user-profiles/actions'
import { SET_RESOLVER_LOCATION } from 'services/resolver/actions'
import { SET_FEATURE_TRACKING_DATA } from 'services/feature-tracking/actions'
import { INTERSTITIAL_SELECT_PROFILE } from 'services/interstitial'
import { refreshUuidOnRefocus } from 'browser/anonymous'
import { EN } from 'services/languages/constants'
import { getPrimary } from 'services/languages'
import { initializePrompt } from 'services/user-profiles'
import { isCheckoutFlowPage } from 'services/url'
import { setNewRelicRoute, addNewRelicAction } from './_newrelic'
import updateGoogleRecaptcha from './_google-recaptcha'
import { init as initViewport } from './_viewport'
import updateRoute from './_change-route'
import updateZendesk from './_zendesk'
import initFacebook from './_facebook'

import {
  SET_APP_BOOTSTRAP_PHASE,
  SET_APP_INTERACTED_CHECK,
  setAppLastInteractedTimestamp,
  setAppInteractedCheck,
  setAppRenderable,
  setAppViewport,
} from './actions'

import {
  BOOTSTRAP_PHASE_COMPLETE,
  BOOTSTRAP_PHASE_INIT,
  APP_RELOAD_INTERVAL_SECONDS,
} from '.'

function onWindowBlur (options = {}) {
  const { dispatch } = options
  const currentTimestamp = Math.floor(Date.now() / 1000)

  dispatch(setAppLastInteractedTimestamp(currentTimestamp))
}

function onWindowFocus (options = {}) {
  const { dispatch } = options

  dispatch(setAppInteractedCheck())
}

export function watchLoginFailed ({ after }) {
  return after(SET_AUTH_LOGIN_FAILED, ({ dispatch }) => {
    dispatch(removeInterstitial())
  })
}

export function watchAuthChange ({ after }) {
  return after([
    AUTH_CHANGE_PROFILE,
    AUTH_LOGIN,
  ], ({ action, state, dispatch }) => {
    const { checkout } = state
    const { type } = action

    if (type === AUTH_LOGIN && checkout.get('account')) {
      return
    }

    dispatch(setInterstitial())
  })
}

export function watchUserDataReady ({ after }) {
  return after([
    USER_PROFILES_SET,
    SET_AUTH_DATA,
    SET_USER_DATA,
    SET_FEATURE_TRACKING_DATA,
  ], ({ state, dispatch }) => {
    const {
      userProfiles, featureTracking, auth, user, app,
    } = state
    const userProfileSwitchInProgress = userProfiles.get('profileSwitch')

    if (getAuthIsLoggedIn(auth)
      && !userProfileSwitchInProgress
      && userProfiles.get('data', List()).size
      && featureTracking.get('data', List()).size
      && user.getIn(['data', 'uid']) === auth.get('uid')) {
      const shouldPrompt = userProfiles.get('promptProfileSelector')
      const appShouldRender = app.get('renderApp')
      const preference = featureTracking.getIn(['data', 'dismissedProfileChooser'], false)

      // if the user has not completed checkout, remove the interstitial
      if (!user.getIn(['data', 'checkout_complete'])) {
        dispatch(removeInterstitial())
        return
      }

      // if the user has not selected a profile yet...
      // this is typically the situation where a users first logs in
      if (shouldPrompt) {
        dispatch(setInterstitial(
          INTERSTITIAL_SELECT_PROFILE,
        ))
        return
      }

      // if the user has chosen to not have the profile selector show
      if (preference) {
        dispatch(removeInterstitial())
        return
      }

      // if the routing system (and content) is not enabled,
      // now it the time to enable them so when the interstitial is removed
      // the user can see the damn page content!
      if (!appShouldRender) {
        dispatch(setAppRenderable(true))
      }
      // remove interstitial
      dispatch(removeInterstitial())
    }
  })
}

export function watchBootstrapPhaseComplete ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, ({ state, dispatch }) => {
    const { app, auth } = state
    const now = (new Date()).getTime()
    const time = app.get('initializationStartTime')

    addNewRelicAction('PageInitialization', {
      timeInSeconds: Math.floor(now / 1000) - Math.floor(time / 1000),
      timeInMilliseconds: now - time,
      startDate: time,
      endDate: now,
    })

    initFacebook(state)
    initViewport((viewport) => {
      dispatch(setAppViewport(viewport))
    })

    if (getAuthIsLoggedIn(auth)) {
      const action = initializePrompt({
        profileCount: state.userProfiles.get('data', List()).size,
        featureTracking: state.featureTracking,
        resolver: state.resolver,
        user: state.user,
        app: state.auth,
      })
        ? showPrompt(true)
        : setAppRenderable(true)
      dispatch(action)
    }
  }).when((o) => (
    get(o, 'action.payload.phase')
      === BOOTSTRAP_PHASE_COMPLETE
  ))
}

export function watchBootstrapPhaseInit ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, ({ state, dispatch }) => {
    const { auth } = state
    if (!getAuthIsLoggedIn(auth)) {
      refreshUuidOnRefocus((refreshedUuid) => {
        dispatch(setAnonymousUuid(refreshedUuid))
      })
    }
  }).when((o) => (
    get(o, 'action.payload.phase')
      === BOOTSTRAP_PHASE_INIT
  ))
}

export function watchAppBootstrapInitAndLogin ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_AUTH_LOGIN_SUCCESS,
  ], (
    {
      state,
      action,
      dispatch,
    },
  ) => {
    const { auth } = state
    const { payload } = action
    const loggedInBootstrapPhaseInit = getAuthIsLoggedIn(auth) && action.type === SET_APP_BOOTSTRAP_PHASE && get(payload, 'phase') === BOOTSTRAP_PHASE_INIT
    const loginSuccess = action.type === SET_AUTH_LOGIN_SUCCESS && auth.get('success')

    // if the user is logged in add listeners on init bootstrap
    // or if the user logges in, add listeners
    if (loggedInBootstrapPhaseInit || loginSuccess) {
      if (global && global.addEventListener) {
        global.addEventListener('focus', () => onWindowFocus({ dispatch }))
        global.addEventListener('blur', () => onWindowBlur({ dispatch }))
      }
    }
  })
}

export function watchSetAppInteractedCheck ({ after }) {
  return after([
    SET_APP_INTERACTED_CHECK,
  ], (
    {
      state,
    },
  ) => {
    const { app, resolver } = state
    const path = resolver.get('path')
    const lastInteractedTimestamp = app.get('lastInteractedTimestamp')
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const elapsedInteractionTime = currentTimestamp - lastInteractedTimestamp
    const appShouldReload = !isCheckoutFlowPage(path)
      && elapsedInteractionTime >= APP_RELOAD_INTERVAL_SECONDS

    if (appShouldReload && global && global.location && global.location.reload) {
      global.location.reload()
    }
  })
}

export function updateZendeskOnSetResolverLocation ({ before }) {
  return before(SET_RESOLVER_LOCATION, ({ action, state }) => {
    const { payload = {} } = action
    const { location = {} } = payload
    const { resolver = {}, user, auth } = state
    const isLoggedIn = getAuthIsLoggedIn(auth)
    const userLanguage = getPrimary(user.getIn(['data', 'language'], List([EN])))

    // all other updateZendesk calls are handled by updateOnPageView
    const locationFullplayerQuery = get(location, ['query', 'fullplayer'])
    const resolverFullplayerQuery = resolver.getIn(['query', 'fullplayer'])

    // fire updateZendesk if we are transitioning from a non-fullplayer page
    // to a fullplayer page
    if (locationFullplayerQuery && !resolverFullplayerQuery) {
      updateZendesk(location, userLanguage, isLoggedIn)
    }

    // fire updateZendesk if we are transitioning from a fullplayer page
    // to a non-fullplayer page
    if (!locationFullplayerQuery && resolverFullplayerQuery) {
      updateZendesk(location, userLanguage, isLoggedIn)
    }
  })
}

export function setZendeskLanguage ({ after }) {
  return after([
    SET_USER_DATA_LANGUAGE,
  ], async ({ state }) => {
    const { resolver, auth, user } = state
    const location = resolver.get('location')
    const isLoggedIn = getAuthIsLoggedIn(auth)
    const userLanguage = getPrimary(user.getIn(['data', 'language'], List([EN])))
    updateZendesk(location, userLanguage, isLoggedIn)
  })
}

export function updateOnPageView ({ before }) {
  return before(SET_EVENT_PAGE_VIEWED, ({ action, state }) => {
    const { payload = {} } = action
    const { location = {} } = payload
    const { auth, user, config } = state
    const isLoggedIn = getAuthIsLoggedIn(auth)
    const userLanguage = getPrimary(user.getIn(['data', 'language'], List([EN])))
    updateZendesk(location, userLanguage, isLoggedIn)
    updateGoogleRecaptcha(location, auth, config)
    setNewRelicRoute(location, auth)
    updateRoute(location)
  })
}

export function updateNewRelic ({ after }) {
  return after(SET_AUTH_DATA, ({ state }) => {
    const { resolver, auth } = state
    const location = resolver.get('location')
    setNewRelicRoute(location, auth)
  })
}
