import React from 'react'
import PropTypes from 'prop-types'

export const ICON_STYLES = {
  OUTLINE: 'outline',
  FILL: 'fill',
  TEAL_CIRCLE: 'teal-circle',
  BANNER: 'banner',
}

export const ICON_TYPES = {
  AIRPLAY: 'airplay',
  ALERT: 'alert',
  ALERT_OUTLINE: 'alert-outline',
  ALL: 'all',
  ARROW_DOWN: 'arrow-down',
  ARROW_DOWN_OUTLINE: 'arrow-down-outline',
  ARROW_UP: 'arrow-up',
  ARROW_UP_OUTLINE: 'arrow-up-outline',
  ARTICLE: 'article',
  ARTICLES: 'articles',
  BELL: 'bell',
  BELL_OFF: 'bell-off',
  BODY: 'body',
  BROWSE: 'browse',
  CAMERA: 'camera',
  CC: 'cc',
  CHECK: 'check',
  CHECKBOX_CHECKED: 'checkbox-checked',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CIRCULAR_ADD: 'circular-add',
  CIRCULAR_BELL_OFF: 'circular-bell-off',
  CIRCULAR_CHECK: 'circular-check',
  CIRCULAR_CHEVRON_LEFT: 'circular-chevron-left',
  CIRCULAR_CHEVRON_RIGHT: 'circular-chevron-right',
  CIRCULAR_COMMENTS: 'circular-comments',
  CIRCULAR_DETAILS: 'circular-details',
  CIRCULAR_ERROR: 'circular-error',
  CIRCULAR_EXPAND: 'circular-expand',
  CIRCULAR_FOLLOW_BELL: 'circular-follow_bell',
  CIRCULAR_INFO: 'circular-info',
  CIRCULAR_MINIMIZE: 'circular-minimize',
  CIRCULAR_PLAY: 'circular-play',
  CIRCULAR_PREVIEW: 'circular-preview',
  CIRCULAR_REMOVE: 'circular-remove',
  CIRCULAR_SERIES: 'circular-series',
  CIRCULAR_SHARE: 'circular-share',
  CLOCK: 'clock',
  CLOCK_FILL: 'clock-fill',
  CLOSE: 'close',
  CLOSE_2: 'close-2',
  COLLAPSE: 'collapse',
  COMMENT: 'comment',
  COMMENT_OUTLINE: 'comment-outline',
  CONSCIOUSNESS: 'consciousness',
  COPY: 'copy',
  COVERUPS: 'coverups',
  DELETE: 'delete',
  DOCS_AND_FILMS: 'docs-and-films',
  DOWNLOAD: 'download',
  ELLIPSIS: 'ellipsis',
  EMBED: 'embed',
  EMAIL: 'email',
  EPISODES: 'episodes',
  EVENTS: 'events',
  EXPAND: 'expand',
  EYE: 'eye',
  FILTER_VERTICAL: 'filter-vertical',
  FLAG: 'flag',
  FRIENDS: 'friends',
  FRIENDS_2: 'friends-2',
  GEAR_OUTLINE: 'gear-outline',
  GIFT_OUTLINE: 'gift-outline',
  GROWTH: 'growth',
  GUIDE: 'guide',
  HEADPHONES: 'headphones',
  HEALING: 'healing',
  HEALTH: 'health',
  HEART: 'heart',
  HEART_FILL: 'heart-fill',
  HEART_OFF: 'heart-off',
  HEART_OUTLINE: 'heart-outline',
  HIDE: 'hide',
  HIDE_2: 'hide-2',
  HOME: 'home',
  HOURGLASS: 'hourglass',
  INFO: 'info',
  LIBRARY: 'library',
  LIGHTHOUSE: 'lighthouse',
  LINK: 'link',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MEDICINE: 'medicine',
  MEDICINE_2: 'medicine-2',
  MEDITATION: 'meditation',
  MENU: 'menu',
  METAPHYSICS: 'metaphysics',
  METAPHYSICS_2: 'metaphysics-2',
  MIC: 'mic',
  MIND: 'mind',
  MINUS: 'minus',
  NEW_VIDEOS: 'new-videos',
  ORIGINS: 'origins',
  PADLOCK: 'padlock',
  PAGINATION_FIRST: 'pagination-first',
  PAGINATION_LAST: 'pagination-last',
  PAGINATION_LEFT: 'pagination-left',
  PAGINATION_NEXT: 'pagination-next',
  PAUSE: 'pause',
  PDF: 'pdf',
  PDF_2: 'pdf-2',
  PENCIL: 'pencil',
  PHONE: 'phone',
  PLAY: 'play',
  PLAY_CIRCLE: 'play-circle',
  PLUS: 'plus',
  PREVIEW: 'preview',
  PRINTER: 'printer',
  RECOMMEND: 'recommend',
  RENAME: 'rename',
  REPLAY: 'replay',
  SCHEDULE: 'schedule',
  SEARCH: 'search',
  SECRETS: 'secrets',
  SERIES: 'series',
  SHARE: 'share',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  PINTEREST: 'pinterest',
  REDDIT: 'reddit',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  SPIRIT: 'spirit',
  STAR_FILL: 'star-fill',
  SUPERNATURAL: 'supernatural',
  SWITCH_LANGUAGE: 'switch-language',
  SWITCH_LANGUAGE_OUTLINE: 'switch-language-outline',
  THIRD_EYE: 'third-eye',
  TV: 'tv',
  UNEXPLAINED: 'unexplained',
  VOLUME: 'volume',
  VOLUME_FULL: 'volume-full',
  VOLUME_LOW: 'volume-low',
  VOLUME_NULL: 'volume-null',
  YOGA: 'yoga',
  YOGA_2: 'yoga-2',
  WEBSITE: 'link',
}

function getClass (props) {
  const { type, style, className } = props
  const cls = ['icon-v2']
  if (type) cls.push(`icon-v2--${type}`)
  if (style) cls.push(`icon-v2--${style}`)
  if (className) cls.push(className)
  return cls.join(' ')
}

function IconV2 (props) {
  const { onClick, iconStyle = {} } = props
  return (
    <span
      className={getClass(props)}
      role="presentation"
      onClick={onClick}
      style={iconStyle}
    />
  )
}

IconV2.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOf([
    ICON_STYLES.OUTLINE,
    ICON_STYLES.FILL,
    ICON_STYLES.TEAL_CIRCLE,
    ICON_STYLES.BANNER,
  ]),
  onClick: PropTypes.func,
}

export default React.memo(IconV2)
