import { get as apiGet, post as apiPost, TYPE_BROOKLYN_JSON } from 'api-client'
import { fromJS } from 'immutable'
import { isNil } from 'lodash'
import { postBrooklyn as postEmailCapture } from 'services/email-signup'
import {
  setLocalPreferences,
} from 'services/local-preferences'
import {
  SET_V5_ONBOARDING_DATA,
  GET_V5_ONBOARDING_DATA,
  POST_V5_ONBOARDING_DATA,
  SET_ONBOARDING_COMPLETED,
  SET_V5_ONBOARDING_DATA_ERROR,
  GET_V14_ONBOARDING_CHOICES,
  SET_V14_ONBOARDING_DATA,
  SET_V14_ONBOARDING_DATA_ERROR,
  POST_V14_ONBOARDING_DATA,
  GET_USER_ONBOARDING_DATA,
  SET_SELECTED_USER_CHOICES,
  SET_V14_SELECTED_USER_CHOICES_COMPLETED,
} from './actions'

export function getV5Onboarding ({ takeEvery }) {
  return takeEvery(GET_V5_ONBOARDING_DATA, async ({ action, state }) => {
    const route = '/v5/user/onboard-choices'
    const { auth } = state
    const { payload } = action
    const { language } = payload

    try {
      const res = await apiGet(
        route,
        { language },
        { languageShouldBeString: true, auth },
        TYPE_BROOKLYN_JSON,
      )

      const data = fromJS(res.body)
      return {
        type: SET_V5_ONBOARDING_DATA,
        payload: data,
      }
    } catch (e) {
      return {
        type: SET_V5_ONBOARDING_DATA_ERROR,
        payload: e,
      }
    }
  })
}

export function getv14OnboardingChoices ({ takeEvery }) {
  return takeEvery(GET_V14_ONBOARDING_CHOICES, async ({ action }) => {
    const route = '/user/onboard-choices/1.4'
    const { language } = action.payload

    try {
      const res = await apiGet(
        route,
        { language },
        { languageShouldBeString: true },
        TYPE_BROOKLYN_JSON,
      )

      const data = fromJS(res.body)
      return {
        type: SET_V14_ONBOARDING_DATA,
        payload: data,
      }
    } catch (e) {
      return {
        type: SET_V14_ONBOARDING_DATA_ERROR,
        payload: e,
      }
    }
  })
}

export function postV5Onboarding ({ takeEvery }) {
  const route = '/v5/user/onboard'

  return takeEvery(POST_V5_ONBOARDING_DATA, async ({ action }) => {
    const { payload } = action
    const { terms, auth } = payload
    try {
      apiPost(
        route,
        terms,
        { auth },
        TYPE_BROOKLYN_JSON,
      )
      const onboardVersion = '1.3'
      setLocalPreferences(auth.get('uid'), 'onboardingComplete', true, auth)
      setLocalPreferences(auth.get('uid'), 'onboardVersion', onboardVersion, auth)
      return {
        type: SET_ONBOARDING_COMPLETED,
        payload: { completed: true, onboardVersion },
      }
    } catch (e) {
      return {
        type: SET_ONBOARDING_COMPLETED,
        payload: { completed: false, onboardVersion: '' },
      }
    }
  })
}

export function postEmailCaptureV5 ({ after }) {
  return after(POST_V5_ONBOARDING_DATA, async ({ action, state }) => {
    const { payload } = action
    const { inboundTracking } = state
    const { terms: { terms }, auth } = payload
    const email = auth.get('email')
    const onboardingVersion = '1.3'
    const onboardingSegment1 = !isNil(terms[0]) ? terms[0].tid : null
    const onboardingSegment2 = !isNil(terms[1]) ? terms[1].tid : null
    const onboardingSegment3 = !isNil(terms[2]) ? terms[2].tid : null

    postEmailCapture({
      email,
      source: '',
      fields: {
        onboarding_version: onboardingVersion,
        onboarding_segment_1: onboardingSegment1,
        onboarding_segment_2: onboardingSegment2,
        onboarding_segment_3: onboardingSegment3,
      },
      inboundTracking,
    })
  })
}

export function getUserOnboardingData ({ takeEvery }) {
  const route = '/user/onboard'

  return takeEvery(GET_USER_ONBOARDING_DATA, async ({ action }) => {
    const { language, auth } = action.payload
    try {
      const result = await apiGet(
        route,
        { language },
        { auth },
        TYPE_BROOKLYN_JSON,
      )

      return {
        type: SET_SELECTED_USER_CHOICES,
        payload: fromJS({ status: 'success', result: result.body }),
      }
    } catch (e) {
      return {
        type: SET_SELECTED_USER_CHOICES,
        payload: fromJS({ status: 'error', result: {} }),
      }
    }
  })
}

export function postV14Onboarding ({ takeEvery }) {
  const route = '/user/onboard'

  return takeEvery(POST_V14_ONBOARDING_DATA, async ({ action }) => {
    const { payload } = action
    const {
      terms, auth, isSkipped = false, isOnboarding = false,
    } = payload
    const onboardVersion = '1.4'

    try {
      await apiPost(
        route,
        { terms, onboardVersion },
        { auth },
        TYPE_BROOKLYN_JSON,
      )
      if (isSkipped || isOnboarding) {
        setLocalPreferences(auth.get('uid'), 'onboardingComplete', true, auth)
        setLocalPreferences(auth.get('uid'), 'onboardVersion', onboardVersion, auth)
        return {
          type: SET_ONBOARDING_COMPLETED,
          payload: { completed: true, onboardVersion },
        }
      }
      return {
        type: SET_V14_SELECTED_USER_CHOICES_COMPLETED,
      }
    } catch (e) {
      return {
        type: SET_V14_SELECTED_USER_CHOICES_COMPLETED,
      }
    }
  })
}
